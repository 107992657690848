import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import React, { createContext, useContext, useState } from "react";

// Create LoadingContext
const LoadingContext = createContext();

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <Backdrop
        sx={{
          zIndex: 1000,
          color: "#fff",
        }}
        open={loading}
      >
        <center>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress color="inherit" />
            <Typography>Please Wait ...</Typography>
          </Box>
        </center>
      </Backdrop>
      {children}
    </LoadingContext.Provider>
  );
}

// Export the useContext hook for LoadingContext
export const useLoading = () => useContext(LoadingContext);

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardCard from "../components/DashboardTile";
import { People, School } from "@mui/icons-material";
import { networkRequest } from "../utils/network_request";
import { apiAuth } from "../utils/urls";
import { useLoading } from "../utils/LoadingContext";

export default function Dashboard() {
  const [data, setData] = useState([]);

  const { setLoading } = useLoading();

  const fetchData = async () => {
    await networkRequest(
      apiAuth.dashboardData,
      (t) => {
        setLoading(t);
      },
      (res) => {
        // console.log(res.data);
        setData(res.data);
      }
    );
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid xs={6} md={3}>
          <DashboardCard icon={School} title="No of Users" value={data.users} />
        </Grid>
        <Grid xs={6} md={3}>
          <DashboardCard icon={People} title="No of Posts" value={data.posts} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

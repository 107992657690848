import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Card,
  CardContent,
  Collapse,
  ButtonGroup,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { networkRequest } from "../../utils/network_request";
import { apiAuth } from "../../utils/urls";
import { useLoading } from "../../utils/LoadingContext";

export default function Feedback() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openedFeedbackId, setOpenedFeedbackId] = useState(null);
  const [filterType, setFilterType] = useState("unread");
  const { setLoading } = useLoading();

  const loadFeedbacks = useCallback(() => {
    networkRequest(
      apiAuth.getFeedbacks,
      (t) => { setLoading(t) },
      (response) => {
        setFeedbacks(response.data);
      },
      'get',
      {filter: filterType}
    )},[filterType]);

  useEffect(() => {
    loadFeedbacks();
  }, [filterType, loadFeedbacks]);

  const updateReadStatus = (id) => {
    networkRequest(
      `${apiAuth.readFeedback}/${id}`,
      (t) => { setLoading(t) },
      (res) => {
        loadFeedbacks();
      }
    );
  }


  const handleFilterChange = (type) => {
    setFilterType(type);
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        gap="15px"
        alignItems="center"
        sx={{ pr: 2, m: 2, mt: 0, maxWidth: "100vw" }}
      >
        <Grid item>
          <h1>Feedback</h1>
        </Grid>
        <Grid item>
          {/* <TextField
            id="outlined-search"
            label="Search"
            type="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              mt: 2,
              mb: 2,
              width: "220px",
              "& .MuiInputBase-root": { height: "40px" },
              "& .MuiInputLabel-root": { lineHeight: "13px" },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
          {/* <Button variant="contained" sx={{ m: 2, mr: 0 }}>
            <FilterAltIcon />
          </Button> */}
        </Grid>
      </Grid>

      <Grid container justifyContent="center" sx={{ mb: 2 }}>
        <ButtonGroup variant="outlined">
          <Button
            onClick={() => handleFilterChange("all")}
            variant={filterType === "all" ? "contained" : "outlined"}
          >
            All
          </Button>
          <Button
            onClick={() => handleFilterChange("unread")}
            variant={filterType === "unread" ? "contained" : "outlined"}
          >
            Unread
          </Button>
          <Button
            onClick={() => handleFilterChange("read")}
            variant={filterType === "read" ? "contained" : "outlined"}
          >
            Read
          </Button>
        </ButtonGroup>
      </Grid>

      <Grid container spacing={2} sx={{ p: 2 }}>
        {feedbacks.length > 0 ? (
          feedbacks.map((feedback) => (
            <Grid item xs={12} md={6} lg={4} key={feedback.id}>
              <Card
                onClick={() => updateReadStatus(feedback.id)}
                sx={{ cursor: "pointer" }}
              >
                <CardContent>
                  <Typography variant="h6">
                    #{feedback.id}: Feedback from {feedback.name || "Unknown"}
                  </Typography>
                  <Typography variant="body2">
                    contact: {feedback.contact}
                  </Typography>
                  <Typography variant="body2">
                    Feedback: {feedback.feedback}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Posted At: {new Date(feedback.created_at).toLocaleString()}
                  </Typography>
                  <br />
                  <Typography variant="caption" color="text.secondary">
                    Status : {feedback.is_read === '1' ? "Read" : "Unread"}
                  </Typography>
                </CardContent>

                <Collapse
                  in={openedFeedbackId === feedback.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {feedback.feedback}
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="h6" sx={{ p: 2 }}>
            No feedbacks to display.
          </Typography>
        )}
      </Grid>
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Modal,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Stack,
  Pagination,
  Checkbox,
  ButtonGroup,
  Switch,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import { networkRequest } from "../../utils/network_request";
import { apiAuth } from "../../utils/urls";
import { useLoading } from "../../utils/LoadingContext";
import { domain } from "../../utils/domain";
import { debounce } from "lodash";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "auto",
  maxHeight: "80vh",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  p: 3,
  backgroundColor: "white",
  borderRadius: "8px",
};

function Subscriptions() {
  const { setLoading } = useLoading();
  const [subscriptionDetails, setPostDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [currentPage, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  
  const handlePageChange = (event, value) => {
    setPage(value);
  };


  const handleClose = () => setOpen(false);

  const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const fetchSubscriptions = async (
    search = "",
    location_type = "",
    location_id = "",
  ) => {
    try {
      let url = `${apiAuth.getSubscriptions}?page=${currentPage}&like=true`;

      if (search) {
        url = `${apiAuth.getSubscriptions}?search=${search}&like=true&page=${currentPage}`;
      } else if (location_id && location_type) {
        url = `${apiAuth.getSubscriptions}?location_type=${location_type}&location_id=${location_id}&like=true&page=${currentPage}`;
      }

      setSubscriptions([]);
      await networkRequest(
        url,
        (t) => setLoading(t),
        (response) => {
          setSubscriptions(response.data);
          setTotalPages(response.pagination.total_pages);
        }
      );
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, [currentPage]);


  return (
    <>
      <Grid>
        <Grid item>
          <h1>Subscriptions</h1>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "100vw",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f0f0f0",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#4caf50",
            borderRadius: "10px",
            border: "2px solid #f0f0f0",
          },
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right" sx={{ py: 2 }}>
                Sr. No
              </TableCell>
              <TableCell align="left" sx={{ py: 2 }}>
                User Name
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                Plan Name
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                Level
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                Amount
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                Start Date
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                End Date
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                Transaction Id
              </TableCell>
             </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.length > 0 ? (
              subscriptions.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.user_name}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.level}</TableCell>
                  <TableCell align="center">{row.amount}</TableCell>
                  <TableCell align="center">{row.start_date}</TableCell>
                  <TableCell align="center">{row.end_date}</TableCell>
                  <TableCell align="center">{row.merchant_transaction_id}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No Subscriptions found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack spacing={2}>
        <Pagination
          count={totalPages}
          page={currentPage} // Controlled component
          onChange={handlePageChange}
          color="primary"
        />
      </Stack>

      {/* Modal for displaying images and subscription details */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <h2>{subscriptionDetails.title}</h2>
          <Grid container spacing={2}>
            {subscriptionDetails.images?.map((img, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <img
                  src={`${domain}/${img}`}
                  alt={`Post Image ${index + 1}`}
                  style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                />
              </Grid>
            ))}
          </Grid>
          <p>
            <strong>Name:</strong> {subscriptionDetails.username}&nbsp;&nbsp;
            <strong>Contact :</strong> {subscriptionDetails.contact} <br />
            <strong>Created At:</strong> {subscriptionDetails.created_at}
          </p>
          <p>
            <strong>Likes:</strong> {subscriptionDetails.like} &nbsp;&nbsp;&nbsp;
            <strong>Dislikes:</strong> {subscriptionDetails.dislike}&nbsp;&nbsp;&nbsp;
            <strong>Views:</strong> {subscriptionDetails.views}
          </p>
          <p>
            {expanded
              ? subscriptionDetails.description
              : truncateText(subscriptionDetails.description || "", 25)}
            {subscriptionDetails.description &&
              subscriptionDetails.description.split(" ").length > 25 && (
                <span
                  onClick={() => setExpanded(!expanded)}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  {expanded ? " Less" : " Read More"}
                </span>
              )}
          </p>
        </Box>
      </Modal>
    </>
  );
}

export default Subscriptions;

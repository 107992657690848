import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  FormControl,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import { apiAuth } from "../../utils/urls";
import { networkRequest } from "../../utils/network_request";
import { useLocation, useNavigate } from "react-router-dom";

export const AddPlan = () => {
  const { state } = useLocation();

  useEffect(() => {
    if (state?.data) {
      setFormData({
        id: state.data.id || null,
        name: state.data.name || "",
        amount: state.data.amount || "",
        plan_type: state.data.plan_type || "post",
        level: state.data.level || "",
        post_limit: state.data.post_limit || "",
        village_limit: state.data.village_limit || null,
        validity: state.data.validity || "",
      });
    }
  }, [state?.data]);

  const [limited, setLimited] = useState(false);
  const [area, setArea] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    amount: "",
    plan_type: "post",
    level: "",
    village_limit: null,
    post_limit: "",
    validity: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePlanTypeChange = (event) => {
    setFormData({ ...formData, plan_type: event.target.value });
  };

  const handleChangeArea = (event) => {
    setArea(event.target.value);
    setFormData({
      ...formData,
      level: event.target.value.toLowerCase(),
      village_limit:
        event.target.value === "Village" ? formData.village_limit : null,
    });
  };

  const handleSubmit = async () => {
    const planData = {
      ...formData,
      post_limit: limited ? formData.post_limit : "Unlimited",
    };

    const cleanPlanData = Object.fromEntries(
      Object.entries(planData).filter(([key, value]) => value !== null)
    );

    await networkRequest(
      apiAuth.createPlan,
      setLoading,
      (response) => {
        if (state?.data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Plan Updated successfully!",
          });
          navigate(-1);
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Plan created successfully!",
          });
        }

        setFormData({
          name: "",
          amount: "",
          plan_type: "post",
          level: "",
          village_limit: null,
          post_limit: "",
          validity: "",
        });
        setLimited(false);
        setArea("");
      },
      "post",
      cleanPlanData
    );
  };

  return (
    <>
      <Box sx={{ maxWidth: 600, px: 2 }}>
        <Grid
          container
          sx={{ my: 2 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4">Add Plan</Typography>
          </Grid>
        </Grid>
        <Box>
          {/* Plan Name */}
          <TextField
            id="name"
            label="Plan Name"
            variant="filled"
            type="text"
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            sx={{
              m: 1,
              "& .MuiInputBase-root": {
                height: "50px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "15px",
              },
            }}
          />

          {/* Amount */}
          <TextField
            id="amount"
            label="Amount"
            variant="filled"
            type="number"
            fullWidth
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
            sx={{
              m: 1,
              "& .MuiInputBase-root": {
                height: "50px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "15px",
              },
            }}
          />

          {/* Validity */}
          <TextField
            id="validity"
            label="Validity (in days)"
            variant="filled"
            type="number"
            fullWidth
            name="validity"
            value={formData.validity}
            onChange={handleInputChange}
            sx={{
              m: 1,
              "& .MuiInputBase-root": {
                height: "50px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "15px",
              },
            }}
          />

          {/* Plan Type */}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="plan-type-select-label">Plan Type</InputLabel>
            <Select
              labelId="plan-type-select-label"
              id="plan-type-select"
              value={formData.plan_type}
              label="Plan Type"
              onChange={handlePlanTypeChange}
              sx={{
                m: 1,
              }}
            >
              <MenuItem value="post">Post</MenuItem>
              <MenuItem value="lokgram">Lokgram</MenuItem>

            </Select>
          </FormControl>

          {/* Post Limit */}
          <FormControl sx={{ m: 1 }}>
            <FormLabel id="post-limit-radio-group-label">Post Limit</FormLabel>
            <RadioGroup
              row
              aria-labelledby="post-limit-radio-group-label"
              name="post-limit-radio-group"
              value={limited ? "Limited" : "Unlimited"}
              onChange={(event) => setLimited(event.target.value === "Limited")}
            >
              <FormControlLabel
                value="Limited"
                control={<Radio />}
                label="Limited"
              />
              <FormControlLabel
                value="Unlimited"
                control={<Radio />}
                label="Unlimited"
              />
            </RadioGroup>
          </FormControl>

          {limited && (
            <TextField
              id="post_limit"
              label="Enter Number of Posts"
              variant="filled"
              type="number"
              fullWidth
              name="post_limit"
              value={formData.post_limit}
              onChange={handleInputChange}
              sx={{
                m: 1,
                "& .MuiInputBase-root": {
                  height: "50px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "15px",
                },
              }}
            />
          )}

          {/* State */}
          {/* <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="state-select-label">State</InputLabel>
            <Select
              labelId="state-select-label"
              id="state-select"
              value={stateValue}
              label="State"
              onChange={handleStateChange}
              sx={{
                m: 1,
              }}
            >
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* User Area Level */}
          <br />
          <FormControl sx={{ m: 1 }}>
            <FormLabel id="area-level-radio-group-label">
              User Area Level (Region)
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="area-level-radio-group-label"
              name="area-level-radio-group"
              value={area}
              onChange={handleChangeArea}
            >
              <FormControlLabel
                value="District"
                control={<Radio />}
                label="District"
              />
              <FormControlLabel
                value="Taluka"
                control={<Radio />}
                label="Taluka"
              />
              <FormControlLabel
                value="Village"
                control={<Radio />}
                label="Village"
              />
            </RadioGroup>
          </FormControl>

          {/* Number of Villages */}
          {area === "Village" && (
            <TextField
              id="village_limit"
              label="Number of Villages"
              variant="filled"
              type="number"
              fullWidth
              name="village_limit"
              value={formData.village_limit}
              onChange={handleInputChange}
              sx={{
                m: 1,
                "& .MuiInputBase-root": {
                  height: "50px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "15px",
                },
              }}
            />
          )}
          <br />
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Adding Plan..." : "Add Plan"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import logo from "../logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { apiGeneral } from "../utils/urls";
import { useLoading } from "../utils/LoadingContext";

export default function Login() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const { loading, setLoading } = useLoading();

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    let isValidate = true;
    setFormError({});

    if (formData.username === "") {
      isValidate = false;
      setFormError((preData) => ({
        ...preData,
        username: "User name is Required",
      }));
    }

    if (formData.password.length < 6) {
      isValidate = false;
      setFormError((preData) => ({
        ...preData,
        password: "Password length must be at least 6",
      }));
    }

    if (formData.password === "") {
      isValidate = false;
      setFormError((preData) => ({
        ...preData,
        password: "Password must be required",
      }));
    }

    if (isValidate) {
      let data = new FormData();
      data.append("email", formData.username);
      data.append("password", formData.password);

      let config = {
        method: "post",
        url: apiGeneral.login,
        data: data,
      };

      try {
        setLoading(true);
        const response = await axios.request(config);
        localStorage.setItem("access_token", response.data.data.access_token);
        localStorage.setItem("refresh_token", response.data.data.refresh_token);
        navigate("/dashboard");
      } catch (error) {
        Swal.fire({
          title: "Oops",
          text: error.response?.data?.message || "An error occurred!",
          icon: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // Show password toggle
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
      padding={2}
    >
      <Card sx={{ width: "100%", maxWidth: 300, marginTop: 10 }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="30vh"
        >
          <CardMedia
            component="img"
            image={logo}
            alt="logo"
            sx={{ width: 200 }}
          />
        </Box>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h2"
              component="h1"
              sx={{ fontSize: "h4.fontSize" }}
            >
              Login
            </Typography>
          </Box>
          <Box
            component="form"
            onSubmit={handleLogin}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              "& > *": {
                m: 1,
              },
            }}
          >
            <TextField
              sx={{ my: 1.5 }}
              fullWidth
              error={formError.username !== undefined}
              id="filled-error-helper-text"
              label="Username"
              name="username"
              value={formData.username}
              helperText={formError.username}
              variant="filled"
              onChange={handleInput}
            />

            <TextField
              sx={{ my: 1.5 }}
              fullWidth
              error={formError.password !== undefined}
              id="filled-error-helper-text"
              type={showPassword ? "text" : "password"}
              label="Password"
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleShowPassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={formData.password}
              helperText={formError.password}
              variant="filled"
              onChange={handleInput}
            />

            <Button
              type="submit"
              variant="contained"
              sx={{ m: 2, width: 100 }}
              disabled={loading}
            >
              {loading ? "Logging in..." : "Login"}{" "}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
